import DependencyContainer from 'Lib/DependencyContainer';

import PublicationListService from 'Pages/PublicationListPage/Lib/Services/PublicationListService';
import PublicationListContext from 'Pages/PublicationListPage/Lib/PublicationListContext';
import PresentationService from 'Pages/PresentationPage/Lib/Services/PresentationService';
import NotificationService from 'Lib/Services/NotificationService';
import UserService from 'Lib/Services/UserService';
import PublicationContext from 'Lib/PublicationContext';
import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
import PresentationBootstrapService from 'PresentationBoostrap/Lib/Services/PresentationBootstrapService';

export const applicationContextKey = DependencyContainer.name;

export function registerDependencies(): DependencyContainer {
    const applicationContext = new DependencyContainer();

    // Contexts
    applicationContext.registerSingleton(PublicationContext);
    applicationContext.registerSingleton(PublicationListContext);
    applicationContext.registerSingleton(PresentationContext);

    // Services
    applicationContext.registerSingleton(NotificationService);
    applicationContext.registerSingleton(UserService);
    applicationContext.registerSingleton(PublicationListService);
    applicationContext.registerSingleton(PresentationService);
    applicationContext.registerSingleton(PresentationBootstrapService);

    return applicationContext;
}
