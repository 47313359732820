import SingletonDependency from 'Lib/SingletonDependency';
import type DependencyContainer from 'Lib/DependencyContainer';
import { ApiException, AuthenticationClient, UserCredentials } from 'Generated/RestClient.g';
import { EventDefinition } from 'Lib/EventDefinition';
import PublicationContext from 'Lib/PublicationContext';
import type User from 'Lib/Models/User';

export default class UserService extends SingletonDependency {
    public onLogout: EventDefinition<void> = new EventDefinition<void>();
    public onLogin: EventDefinition<User> = new EventDefinition<User>();

    private readonly authenticationClient = new AuthenticationClient();
    private readonly publicationContext: PublicationContext;

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);
        this.publicationContext = applicationContext.get(PublicationContext);
    }

    public async isUserLoggedInAsync(): Promise<boolean> {
        try {
            const userResponse = await this.authenticationClient.getCurrentUser();
            this.publicationContext.currentUser = { name: userResponse.name };
            return true;
        } catch {
            /* ignored */
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async loginAsync(name: string, password: string): Promise<boolean> {
        try {
            await this.authenticationClient.login(new UserCredentials({ name }));
            const userObject = { name } as User;
            this.onLogin.invoke(userObject);
            this.publicationContext.currentUser = userObject;
            return true;
        } catch (error) {
            if (error instanceof ApiException && error.status == 401) {
                console.log('Login failed');
            }
        }

        return false;
    }

    public async logoutAsync(): Promise<boolean> {
        try {
            await this.authenticationClient.logout();
            this.onLogout.invoke();
            this.publicationContext.currentUser = undefined;
            return true;
        } catch (error) {
            console.log('Logout failed');
        }
        return false;
    }
}
