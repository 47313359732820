import {get, type Writable, writable} from 'svelte/store';
import SingletonDependency from 'Lib/SingletonDependency';
import {Scenario} from 'Generated/RestClient.g';
import type {KeyOrderPair} from 'Lib/Models/Order';
import type {ColumnKey} from 'Pages/PublicationListPage/Lib/ColumnKeys';

export default class PublicationListContext extends SingletonDependency {
    public scenarioListObservable: Writable<Scenario[]> = writable(new Array<Scenario>());
    public scenarioListSortingObservable: Writable<KeyOrderPair<ColumnKey>[]> = writable([
        {key: 'createdAt', order: 'desc'},
    ]);
    public pendingUploadCountObservable: Writable<number> = writable(0);
    public scenarioIdForPasswordDialogObservable: Writable<string | undefined> = writable(undefined);

    public get scenarioList(): Scenario[] {
        return get(this.scenarioListObservable);
    }

    public set scenarioList(scenarioList: Scenario[]) {
        this.scenarioListObservable.set(scenarioList);
    }

    public get scenarioListSorting(): KeyOrderPair<ColumnKey>[] {
        return get(this.scenarioListSortingObservable);
    }

    public set scenarioListSorting(scenarioListSorting: KeyOrderPair<ColumnKey>[]) {
        this.scenarioListSortingObservable.set(scenarioListSorting);
    }

    public get pendingUploadCount(): number {
        return get(this.pendingUploadCountObservable);
    }

    public set pendingUploadCount(pendingUploadCount: number) {
        this.pendingUploadCountObservable.set(Math.max(pendingUploadCount, 0));
    }
    
    public set scenarioIdForPasswordDialog(id: string){
        this.scenarioIdForPasswordDialogObservable.set(id);
    }
}
