import SingletonDependency from 'Lib/SingletonDependency';
import { get, writable, type Writable } from 'svelte/store';
import type User from 'Lib/Models/User';
import type { Nullable } from './Utils/Nullable';

export default class PublicationContext extends SingletonDependency {
    public currentUserObservable: Writable<Nullable<User>> = writable(undefined);

    public get currentUser(): Nullable<User> {
        return get(this.currentUserObservable);
    }

    public set currentUser(user: Nullable<User>) {
        this.currentUserObservable.set(user);
    }
}
