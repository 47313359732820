<script lang="ts">
    import { Column, Title } from 'Components/UI';

    import logoImage from 'Assets/Icons/ActiveGraf_Logo.png';
    import translations from 'Assets/i18n';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let message: Nullable<string> = translations.global.messages.unknownError;
</script>

<Column
    horizontalAlign="center"
    verticalAlign="center">
    <img
        alt={message}
        height={100}
        src={logoImage}
        width={100} />
    <Title
        padding="0 16px"
        textAlign="center">
        {message}
    </Title>
</Column>
