import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
import PublicationContext from 'Lib/PublicationContext';
import { ApiException, ScenariosClient } from 'Generated/RestClient.g';
import NotificationService from 'Lib/Services/NotificationService';
import translations from 'Assets/i18n';
import UserService from 'Lib/Services/UserService';

export default class PresentationService extends SingletonDependency {
    private readonly presentationHashPattern: RegExp = /^#\/([a-z0-9]+)/i;
    private readonly translationContext = translations.pages.presentation;

    private readonly presentationContext: PresentationContext;
    private readonly publicationContext: PublicationContext;
    private readonly notificationService: NotificationService;

    private readonly scenariosClient = new ScenariosClient();

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);

        this.presentationContext = applicationContext.get(PresentationContext);
        this.publicationContext = applicationContext.get(PublicationContext);
        this.notificationService = applicationContext.get(NotificationService);
        const userService = applicationContext.get(UserService);

        userService.onLogin.do(async () => {
            if (this.isPresentationOpen()) {
                await this.updateCurrentScenarioAsync();
                this.updatePresentationWritableState();
            }
        });

        userService.onLogout.do(() => {
            this.presentationContext.currentScenario = undefined;
            this.updatePresentationWritableState();
        });

        userService.isUserLoggedInAsync().then(async isLoggedIn => {
            if (isLoggedIn && this.isPresentationOpen()) {
                await this.updateCurrentScenarioAsync();
                this.updatePresentationWritableState();
            }
        });
    }

    private updatePresentationWritableState(): void {
        this.presentationContext.presentationWritable =
            !!this.publicationContext.currentUser &&
            this.presentationContext.currentScenario?.owner === this.publicationContext.currentUser.name;
    }

    public isPresentationOpen(): boolean {
        return this.presentationHashPattern.exec(window.location.hash) !== null;
    }

    public getCurrentScenarioId(): string {
        const match = this.presentationHashPattern.exec(window.location.hash);
        if (!match) {
            throw new Error('Could not find scenario id.');
        }

        return match[1];
    }

    public async saveAsync(): Promise<void> {
        try {
            await this.scenariosClient.saveInstanceChanges(this.getCurrentScenarioId());
            this.notificationService.success(this.translationContext.saveSucceeded);
        } catch (e) {
            this.notificationService.error(this.translationContext.saveFailed);
        }
    }

    private async updateCurrentScenarioAsync(): Promise<void> {
        try {
            this.presentationContext.currentScenario = await this.scenariosClient.getScenario(
                this.getCurrentScenarioId(),
            );
        } catch (error) {
            if (error instanceof ApiException && error.status === 404) {
                this.presentationContext.currentScenario = undefined;
            }
        }
    }
}
