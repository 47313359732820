import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import PublicationListContext from 'Pages/PublicationListPage/Lib/PublicationListContext';
import {ScenarioPasswordUpdateRequest, ScenariosClient} from 'Generated/RestClient.g';
import NotificationService from 'Lib/Services/NotificationService';
import translations from 'Assets/i18n';
import UserService from 'Lib/Services/UserService';

export default class PublicationListService extends SingletonDependency {
    private readonly translationContext = translations.pages.publicationList;

    private readonly publicationListContext: PublicationListContext;
    private readonly notificationService: NotificationService;

    private readonly scenariosClient = new ScenariosClient();

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);

        this.publicationListContext = applicationContext.get(PublicationListContext);
        this.notificationService = applicationContext.get(NotificationService);
        const userService = applicationContext.get(UserService);

        userService.onLogin.do(async () => {
            await this.updateScenarioListAsync();
        });

        userService.onLogout.do(() => {
            this.publicationListContext.scenarioList = [];
            this.publicationListContext.pendingUploadCount = 0;
        });

        userService.isUserLoggedInAsync().then(async isLoggedIn => {
            if (isLoggedIn) {
                await this.updateScenarioListAsync();
            }
        });
    }

    public async uploadScenarioAsync(file: File, adjustPendingCount: boolean = true): Promise<void> {
        if (adjustPendingCount) {
            ++this.publicationListContext.pendingUploadCount;
        }

        try {
            await this.scenariosClient.createScenario(file.name.replace(/.actg$/, '') ,file);

            await this.updateScenarioListAsync();
        } catch (e) {
            console.error('`actg` upload failed.', e);
            this.notificationService.error(this.translationContext.invalidActgFile.format(file.name));
        }

        if (adjustPendingCount) {
            --this.publicationListContext.pendingUploadCount;
        }
    }

    public async updateScenarioListAsync(): Promise<void> {
        const orders = this.publicationListContext.scenarioListSorting.map(
            entry => entry.key + (entry.order === 'desc' ? '|desc' : ''),
        );

        const list = await this.scenariosClient.getScenarios(orders);

        this.publicationListContext.scenarioList = list.results;
    }

    public async deleteScenarioAsync(scenarioId: string): Promise<void> {
        await this.scenariosClient.deleteScenario(scenarioId);

        return this.updateScenarioListAsync();
    }

    public downloadScenario(scenarioId: string): void {
        window.open(`/api/scenarios/${scenarioId}/package`, '_blank');
    }

    public openScenario(scenarioId: string): void {
        window.open(this.getScenarioURI(scenarioId), '_blank');
    }

    public async copyScenarioURIAsync(scenarioId: string): Promise<void> {
        await navigator.clipboard.writeText(this.getScenarioURI(scenarioId));
        this.notificationService.info(this.translationContext.publicationURICopied);
    }

    private getScenarioURI(scenarioId: string): string {
        return `${window.location}#/${scenarioId}`;
    }

    public async uploadScenariosAsync(files: FileList): Promise<void> {
        this.publicationListContext.pendingUploadCount += files.length;
        for (const file of Array.from(files)) {
            await this.uploadScenarioAsync(file, false);
            --this.publicationListContext.pendingUploadCount;
        }
    }

    public setPublicationPassword(id: string, password?: string) {
        return this.scenariosClient.setScenarioPassword(id, new ScenarioPasswordUpdateRequest({password}));
    }

    getPublicationPassword(id: string) {
        return this.scenariosClient.getScenarioPassword(id);
    }
}
