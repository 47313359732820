<script lang="ts">
    import {LoadingPage} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {getContext, hasContext, onDestroy, onMount, setContext} from 'svelte';
    import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
    import DependencyContainer from 'Lib/DependencyContainer';
    import PublicationContext from 'Lib/PublicationContext';
    import PresentationBootstrapService from 'PresentationBoostrap/Lib/Services/PresentationBootstrapService';
    import {applicationContextKey} from 'PublicationDependencies';
    import ErrorPage from 'Components/ErrorPage.svelte';
    import PasswordPromptDialog from 'Pages/PublicationListPage/PasswordPromptDialog.svelte';
    import type {Nullable} from 'Lib/Utils/Nullable';

    let applicationContext: DependencyContainer;

    if (!hasContext(applicationContextKey)) {
        // Embedded mode
        console.info('Presentation bootstrap running in embedded / standalone mode.');

        applicationContext = new DependencyContainer();
        setContext(applicationContextKey, applicationContext);
        onDestroy(() => applicationContext.onDestroy());

        // Contexts
        applicationContext.registerSingleton(PublicationContext);

        // Services
        applicationContext.registerSingleton(PresentationBootstrapService);
    } else {
        console.info('Presentation bootstrap running in publication server mode.');
        applicationContext = getContext<DependencyContainer>(applicationContextKey);
    }

    const {
        presentationLoadedObservable,
        instanceErrorObservable,
        passwordNeededObservable
    } = applicationContext.get(PresentationContext);
    const presentationBootstrapService = applicationContext.get(PresentationBootstrapService);
    
    async function tryOpenWithPassword(password: Nullable<string>) {
        await presentationBootstrapService.startBootstrapProcessAsync(password?.trim());
    }

    onMount(async () => {
        await presentationBootstrapService.startBootstrapProcessAsync();
    });
    
    const scenarioId = presentationBootstrapService.getScenarioId();
    
    const search = window.location.search;
</script>

{#if $instanceErrorObservable}
    <ErrorPage message={$instanceErrorObservable}/>
{:else if $presentationLoadedObservable}
    <iframe
            src={`/instance/${scenarioId}/${search}`}
            title="Presentation"/>
{:else}
    <LoadingPage text={translations.pages.containerLoading.startingNewInstance}/>
{/if}

{#if $passwordNeededObservable}
    <PasswordPromptDialog scenarioId={scenarioId} callback={tryOpenWithPassword}/>
{/if}

<style>
    iframe {
        width: 100vw;
        height: 100%;
        overflow: clip;
    }
</style>
